import { Ordering } from '@wix/comments-ooi-client/controller';
import { isQuestion } from '@wix/communities-forum-client-commons';
import { CommentsOrdering } from '../types';

export const CommentsOrderingMap = {
  latest_first: Ordering.NEWEST_FIRST,
  oldest_first: Ordering.OLDEST_FIRST,
  most_votes_first: Ordering.MOST_VOTES_FIRST,
  least_votes_first: Ordering.LEAST_VOTES_FIRST,
  most_reactions_first: Ordering.MOST_REACTIONS_FIRST,
};

const PAGINATION_CONFIG = {
  replyShowMoreLimit: 8,
  ordering: Ordering.NEWEST_FIRST,
  initialPage: {
    commentLimit: 20,
    replyLimit: 3,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 3,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
};
const PAGINATION_CONFIG_VOTES = { ...PAGINATION_CONFIG, ordering: Ordering.MOST_VOTES_FIRST };

export const getPaginationConfig = (post: any, commentsOrdering: CommentsOrdering) => {
  return {
    ...(isQuestion(post?.postType) ? PAGINATION_CONFIG_VOTES : PAGINATION_CONFIG),
    ...(commentsOrdering ? { ordering: CommentsOrderingMap[commentsOrdering] } : {}),
  };
};
